import React from "react";
import { BsCheckCircleFill, BsCircle, BsCircleFill } from "react-icons/bs";
// import UploadJd from "./UploadJd";
import UploadJd from "./UploadJd";
import SetupInterview from "./SetupInterview";
import UploadResumes from "./UploadResumes";
import { useLocation } from "react-router-dom";

type UploadJdLayoutProps = { setLoading: Function };

const UploadJdLayout: React.FC<UploadJdLayoutProps> = ({
  setLoading,
}: UploadJdLayoutProps) => {
  const { state } = useLocation()
  const [jdId, setJDId] = React.useState(state?.postId);
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [resumes, setResumes] = React.useState([] as any);
  const [fromPage, setFromPage] = React.useState('jd');
  const [roundData, setRoundData] = React.useState({} as any);

  React.useEffect(() => {
    if(state?.postId !== '' && state?.postId !== undefined && state?.postId !== null) {
      localStorage.setItem('createPostId', state?.postId);
    }
    setLoading(true);
    setLoading(false);
  }, []);

  const loadingOpen = (open: boolean): void => setLoading(open);
  const setPostId = () => {
    setFromPage('jd')
    var pId = localStorage.getItem('createPostId');
    setJDId(pId!);
    setSelectedPage(1);
  };
  const setLevel = (data : any) => {
    setRoundData(data);
    var pId = localStorage.getItem('createPostId');
    setJDId(pId!);
    setSelectedPage(2);
  }
  const setBackUploadJd = () => {
    var pId = localStorage.getItem('createPostId');
    setJDId(pId!);
    setSelectedPage(0);
  }
  const setBackJdRound = (resumeList : any) => {
    setFromPage('resume')
    var pId = localStorage.getItem('createPostId');
    setJDId(pId!);
    setResumes(resumeList)
    setSelectedPage(1);
  }
  return (
    <>
      {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer> */}
      <div
        className="flex flex-col items-center overflow-y-auto"
        style={{ height: "84vh" }}
      >
        <div className="flex justify-between w-full py-3 px-10">
          <div className="flex flex-col items-start w-full"></div>
          <div className="flex flex-col items-start w-full gap-2">
            <h6
              className="text-sm font-semibold"
              
            >
              Create JD
            </h6>
            <h1 className="flex items-center w-full">
              {selectedPage > 0 ? (
                <BsCheckCircleFill color="blue" size={20} />
              ) : selectedPage === 0 ? (
                <BsCircleFill color="blue" size={20} />
              ) : (
                <BsCircle color="blue" size={20} />
              )}
              <span
                className={
                  selectedPage >= 1
                    ? "border-2 w-full border-blue-500"
                    : "border-2 w-full"
                }
              ></span>
            </h1>
          </div>
          <div className="flex flex-col items-start w-full gap-2">
            <h6
              className="text-sm font-semibold"
              
            >
              Setup Interview
            </h6>
            <h1 className="flex items-center w-full">
              {selectedPage > 1 ? (
                <BsCheckCircleFill color="blue" size={20} />
              ) : selectedPage === 1 ? (
                <BsCircleFill color="blue" size={20} />
              ) : (
                <BsCircle color="blue" size={20} />
              )}
              <span
                className={
                  selectedPage >= 2
                    ? "border-2 w-full border-blue-500"
                    : "border-2 w-full"
                }
              ></span>
            </h1>
          </div>
          <div className="flex flex-col items-start w-full gap-2">
            <h6
              className="text-sm font-semibold"
              
            >
              Upload Candidate
            </h6>
            <h1 className="flex items-center w-full">
              {selectedPage > 2 ? (
                <BsCheckCircleFill color="blue" size={20} />
              ) : selectedPage === 2 ? (
                <BsCircleFill color="blue" size={20} />
              ) : (
                <BsCircle color="blue" size={20} />
              )}
            </h1>
          </div>
        </div>
        <div className="p-4 border w-3/4 my-5 h-screen overflow-y-auto">
          {selectedPage === 0 && (
            <UploadJd setLoading={loadingOpen} postId={jdId} setPostId={setPostId} />
          )}
          {selectedPage === 1 && (
            <SetupInterview setLoading={loadingOpen} setLevel={setLevel} setBackUploadJd={setBackUploadJd} fromPage={fromPage} />
          )}
          {selectedPage === 2 && (
            <UploadResumes setLoading={loadingOpen} setBackJdRound={setBackJdRound} resumeList={resumes} roundData={roundData} />
          )}
        </div>
      </div>
    </>
  );
};
export default UploadJdLayout;
