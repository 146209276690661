import { FC } from "react";

const DATA = [
  {
    T1: "1 to 2",
    T2: "Lacks overall competency, needs improvement (Rejected)",
  },
  {
    T1: "2 to 2.5",
    T2: "Limited competency, scalable in shorter term (Junior level)",
  },
  {
    T1: "2.5 to 3.5",
    T2: "Appreciable and broader competency, rapidly scalable (Mid / Senior level)",
  },
  {
    T1: "3.5 to 4.5",
    T2: "Demonstrated competency, consistently adaptive (Lead / Architect level)",
  },
  { T1: "4.5 to 5", T2: "Proficient competency, and progressive (SME level)" },
];
interface ScoringDefinitionProps {
  tag: Boolean;
}
const ScoringDefinition: FC<ScoringDefinitionProps> = ({ tag }) => {
  return (
    <div className={tag ? "disabled-div" : ""}>
      <p className="font-semibold text-xs underline text-gray-900">
        Scoring Definitions
      </p>
      {DATA.map((e, index) => {
        return (
          <div
            key={index}
            className="grid grid-cols-11 ml-6 items-center justify-start text-xs text-gray-600 gap-1"
          >
            <p className="col-span-3 w-full">{e.T1}</p>
            <p className="col-span-1 w-full">-</p>
            <p className="col-span-7 w-full">{e.T2}</p>{" "}
          </div>
        );
      })}
    </div>
  );
};
export default ScoringDefinition;
